@import '../../styles/customMediaQueries.css';

.root {
    margin-bottom: 24px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.mainLabel {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.2px;
    padding-bottom: 11px;
}

.box {
    padding: 14px 17px 13px 17px;
    border-radius: 6px;
    background: #F7F7F7;

    color: #4A4A4F;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}