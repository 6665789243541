@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.collapsibleList {
  margin: 0 0 17px 0;
  padding: 17px 16px 6px 16px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #D8DCE6;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 93%, #fafafa 93%);

  @media (--viewportMedium) {
    background: unset;
  } */
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 2px 0 6px 0;
  }
}

.labelSpacing {
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.collapsibleWrapper {
  cursor: pointer;
  width: 100%;
  height: 82px;
  z-index: 100;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  padding: 18px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fafafa 100%);

  @media (--viewportMedium) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #ffffff 100%);
  }
}

.expandedCollapsibleWrapper {
  background: unset;
  width: 33%;
  @media (--viewportMedium) {
    width: 50%;
  }
}

.flippedArrowIcon {
  transform: rotateX(180deg);
}