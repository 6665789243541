@import '../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    margin-left: 0;
  }
}

.fontInput {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 5px 13px 15px 13px;
    margin: 0;
  }
}

.mobileInput {
  /* Font */
  composes: fontInput;

  /* Layout */
  height: 50px;

  /* Borders */
  border: 0;
  border-radius: 0;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 0;
  /* searchContainer margin-top (94px) + mobileInput height (50px) = 144px */
  min-height: calc(100vh - 144px);
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    min-height: unset;
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: var(--topbarHeightDesktop);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.setRela {
  position: relative;
  /* width: 80%;
  left: 10%; */

  /* overflow: hidden; */
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    left: 0%;
  }
}

.setRela input {
  /* padding: 0px 40px 0px 66px; */
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  letter-spacing: -0.305px;
  /* padding: 10px 40px 12px 76px; */
  padding: 26px 40px 27px 76px;
  border-radius: 90px;
  box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid white;

  @media (--viewportMedium) {
    padding: 30px 40px 31px 76px;
  }
}

.setRela input:focus {
  /* background-color: #fff; */
  border-color: #80bdff;
  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
  transition: all ease-in-out 100ms;
}

.setIcon {
  position: absolute;
  left: 24px;
  /* Adjust the right position to place the icon where you want */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
}

.inputHeight {
  height: 73px;

  @media (--viewportMedium) {
    height: 79px;
  }
}

.setTopBarSearch {
  position: relative;
  /* width: 50%; */
  left: 19px;
  width: 552px;
  height: 42px;
  flex-shrink: 0;

  @media (--viewportLargeWithPaddings) {
    left: 20px;
  }

  @media (min-width: 1300px) {
    left: 36px;
  }
}

.setTopBarSearch input {
  /* padding-left: 60px; */
  /* padding: 5px 16px 5px 50px; */
  padding: 11px 16px 11px 49px;
  line-height: normal;
  font-size: 14px;
}

.setTopBarInputHeight {
  border-radius: 33px;
}

.topBarIcon {
  position: absolute;
  /* left: 14px;
  top: 50%; */
  left: 18px;
  top: 49%;
  transform: translateY(-50%);
  cursor: pointer;
}

.topBarPadding {
  padding: 15px 0px;
  height: 72px;
}

.topBarSearchIcon {
  width: 19px;
  height: 20px;
}

.searchIcon {
  width: 31.35px;
  height: 33px;

  @media (--viewportMedium) {
    width: 39.81px;
    height: 40px;
  }
}
