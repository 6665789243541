@import '../../styles/customMediaQueries.css';

.subDescription {
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    color: #737373;
    display: block;
    margin-bottom: 6px;
    margin-top: 3px;
  
    @media (--viewportMedium) {
      margin-bottom: 13px;
      margin-top: -7px;
    }
  }