@import '../../../styles/customMediaQueries.css';

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
  /* margin-top: 8px; */
  width: 240px;
  margin: auto;
}

.setLowerCase {
  text-transform: lowercase;
  color: hsl(240 3% 30%);
  text-decoration: underline;
}
