@import '../../../styles/customMediaQueries.css';

.root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 15px 0 0;

    @media (--viewportLarge) {
        padding: 0;
        margin-left: 9px;
    }

    &:hover {
        cursor: pointer;
    }
}

.root>a>svg {
    width: 23px;
}

.counter {
    position: absolute;
    top: 10px;
    right: 9px;
    background-color: #EB001B;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: -0.2666666805744171px;

    @media (--viewportMedium) {
        top: 18px;
        right: 9px;
    }

    @media (--viewportLarge) {
        top: 18px;
        right: -6px;
    }
}