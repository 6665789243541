@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  /* margin: 30px 0 0 0; */
  /* margin: var(--TopbarMobileMenu_topMargin) 0 0 0; */
  margin: 27px 0 0 0;
  height: 62px;
  width: 62px;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 21px; /*24px */
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 152.381% */ /*24px previous */
  letter-spacing: -0.75px;
  padding: 3px 0 3px 0;
  margin-bottom: 1px;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  /* font-size: 18px;
  line-height: 24px; */
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 188.235% */
  letter-spacing: -0.75px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  /* color: var(--marketplaceColor); */
  position: relative;

  margin-top: auto;
  margin-bottom: 0;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.75px;
  color: #2a7cb4;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  /* color: var(--marketplaceColor); */

  /* Layout */
  margin: 0;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.75px;
  color: #2a7cb4;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  /* top: 4px; */
  top: 13px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 24px;
  margin-top: 30px;
  /* margin-top: var(--TopbarMobileMenu_topMargin); */
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.navigateLink {
  display: grid;
  margin: 60px 0px 23px 0px;
}

.title {
  margin: 10px 0px;
}

.navLink {
  margin: 0;
  text-decoration: none;
  color: #4a4a4a;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px; /* 217.647% */
  letter-spacing: -0.75px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.divider {
  border-top: 1px solid #ddd; /* Adjust the styling as needed */
  /* margin: 16px 0; /* Adjust the margin as needed */
}
